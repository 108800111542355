/* eslint-disable quotes */
/* eslint-disable no-undef */

'use strict';

module.exports.sendRequest = function (param, payload, form) {
	const baseUrl = 'https://cbox.mydigitalorange.ro/7a84647039b/orogames/';
	const apiKey = ']x<YkW2Eqxj6LkimasW^8xKFR<pK-W';

	payload.key = apiKey;

	let settings = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload)
	};

	return fetch(`${baseUrl}${param}`, settings)
		.then(handleErrors)
		.then(function (response) {
			return response.json();
		})
		.catch(function () {
			form.querySelector('.main-error-container').classList.remove('d-none');
			form.querySelector('.main-error-container').textContent = 'A avut loc o eroare. Te rugăm sa incerci din nou';

			return Promise.reject();
		});

	function handleErrors(response) {
		if (!response.ok) {
			return Promise.reject();
		}

		return response;
	}
};


