'use strict';

const mainFormSection = document.querySelector('.section-form--main');
const checkPrizesSection = document.querySelector('.section-form--check-prizes');

module.exports.secondaryBtnsHandlers = function () {
	const btns = document.querySelectorAll('.btn-transparent');

	btns.forEach(function (btn) {
		btn.addEventListener('click', function () {

			if (this.closest('.section-form--main')) {
				handleAlreadyWon();
				return;
			}

			checkPrizesSection.classList.remove('active-section');
			mainFormSection.classList.add('active-section');
			resetForm(checkPrizesSection);
		});
	});

	function handleAlreadyWon() {
		checkPrizesSection.classList.add('active-section');
		mainFormSection.classList.remove('active-section');
		resetForm(mainFormSection);
	}
};

module.exports.showMainFormBtnHandler = function () {
	const btns = document.querySelectorAll('.section-hero .btn.btn-primary');

	btns.forEach(function (btn) {
		btn.addEventListener('click', function () {
			mainFormSection.classList.add('active-section');
			document.querySelector('.section-main-page').classList.add('d-none');
		});
	});
};

module.exports.showMoreTermsHandler = function () {
	const btns = document.querySelectorAll('.showMore-btn');

	btns.forEach(function (btn) {
		btn.addEventListener('click', function () {
			this.classList.add('d-none');
			this.classList.remove('d-inline-flex');
			this.closest('.form-group').querySelector('.hidden-content').classList.remove('d-none');
		});
	});
};

module.exports.handleCloseSection = function () {
	const closeBtns = document.querySelectorAll('.section-form__close');

	closeBtns.forEach(function (btn) {

		btn.addEventListener('click', function () {
			const currentForm = this.closest('section');
			const mainPage = document.querySelector('.section-main-page');

			currentForm.classList.remove('active-section');
			mainPage.classList.remove('d-none');
			resetForm(currentForm);
			resetConfirmationMessages(currentForm);
		});
	});
};

module.exports.handleWinnersDisplay = function () {
	const showWinnersTrigger = document.querySelector('.raffle-winners-trigger');
	const winnersContainer = document.querySelector('.raffle-winners');

	showWinnersTrigger.addEventListener('click', function () {
		winnersContainer.classList.remove('d-none');
	});

	winnersContainer.addEventListener('click', function (e) {
		if (!e.target.classList.contains('raffle-winners__inner')) {
			winnersContainer.classList.add('d-none');
		}
	});
};

function resetForm(form) {
	const allInputs = form.querySelectorAll('input');

	allInputs.forEach(function (input) {
		const currentFormGroup = input.closest('.form-group');

		if (input.type === 'checkbox') {
			input.checked = false;
		} else {
			input.value = '';
		}

		currentFormGroup.classList.remove('form-group--error');
	});

	removeErrors(form);
}

module.exports.resetFormOnRequestSuccess = function (form) {
	const allInputs = form.querySelectorAll('input');

	allInputs.forEach(function (input) {
		const currentFormGroup = input.closest('.form-group');

		if (input.type === 'checkbox') {
			input.checked = false;
		} else {
			input.value = '';
		}

		currentFormGroup.classList.remove('form-group--error');
	});

	removeErrors(form);
};

function removeErrors(form) {
	if (form.querySelector('.main-error-container')) {
		form.querySelector('.main-error-container').classList.add('d-none');
	}

	if (form.querySelector('.message-already-played')) {
		form.querySelector('.message-already-played').classList.add('d-none');
	}

	if (form.querySelector('.message-already-winner')) {
		form.querySelector('.message-already-winner').classList.add('d-none');
	}
}

function resetConfirmationMessages(currentForm) {
	if (currentForm.classList.contains('section-form--winner')) {
		currentForm.classList.remove('text-center');
		currentForm.querySelector('.form__confetti').classList.remove('d-none');
		currentForm.querySelector('.form__confetti').classList.add('d-flex');
		currentForm.querySelector('.form__info').classList.remove('d-none');
		currentForm.querySelector('.winner-form').classList.remove('d-none');
		currentForm.querySelector('.btn-submit').classList.remove('d-none');
		currentForm.querySelector('a.btn-primary').classList.add('d-none');
		currentForm.querySelector('.succes-message-container').classList.add('d-none');

		if (!currentForm.querySelector('.form-group--voucher-pin').classList.contains('d-none')) {
			currentForm.querySelector('.form-group--voucher-pin').classList.add('d-none');
		}
	}
}

module.exports.resetForm = resetForm;