/* eslint-disable no-useless-escape */
'use strict';

const validateCheckboxes = function (form) {
	const checkboxes = form.querySelectorAll('input[type="checkbox"]');

	checkboxes.forEach(function (el) {
		if (!el.checked === true) {
			el.closest('.form-group--checkbox').classList.add('form-group--error');
			form.querySelector('.main-error-container').classList.remove('d-none');
		}
	});
};

const validateEmail = function (form) {
	const emailRgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const emailInput = form.querySelector('.form-control__email-input');

	if (!emailRgx.test(emailInput.value)) {
		emailInput.closest('.form-group').classList.add('form-group--error');
		form.querySelector('.main-error-container').classList.remove('d-none');
		form.querySelector('.main-error-container').textContent = 'Adresa de email este incorectă.';
	}
};

const validateRaffleCode = function (form) {
	const raffleCodeInput = form.querySelector('input.form-control__raffle-code');

	if (raffleCodeInput.value.length !== 10) {
		highlightErrorInput(raffleCodeInput);
	}
};

const validatePhoneNumber = function (form) {
	const input = form.querySelector('input.form-control__phone-input');
	const inputValue = input.value;
	const regExp = /[a-zA-Z]/g;

	const hasLetters = regExp.test(inputValue);
	const incorrectLength = inputValue.length !== 10;
	const incorectFirstTwoDigits = inputValue.substring(0, 2) !== '07';

	if (hasLetters || incorrectLength || incorectFirstTwoDigits) {
		highlightErrorInput(input);
	}
};

const removeErrorOnFocus = function (form) {
	const elements = form.querySelectorAll('input[type="text"]');

	elements.forEach(function (el) {
		el.addEventListener('focus', function () {
			const formGroup = this.closest('.form-group--input');

			formGroup.classList.remove('form-group--error');

			form.querySelector('.main-error-container').classList.add('d-none');

			if (form.querySelector('.message-already-played')) {
				form.querySelector('.message-already-played').classList.add('d-none');
			}
		});
	});
};

const removeErrorOnChange = function (form) {
	const checkboxes = form.querySelectorAll('input[type="checkbox"]');

	checkboxes.forEach(function (checkbox) {
		checkbox.addEventListener('change', function () {
			this.closest('.form-group').classList.remove('form-group--error');
			form.querySelector('.main-error-container').classList.add('d-none');
		});
	});
};

function highlightErrorInput(input) {
	const formGroup = input.closest('.form-group--input');

	formGroup.classList.add('form-group--error');
}

module.exports.isValidForm = function (form) {
	if (form.querySelector('.form-control__phone-input')) {
		validatePhoneNumber(form);
		removeErrorOnFocus(form);
	}

	if (form.querySelector('input[type="checkbox"]')) {
		validateCheckboxes(form);
		removeErrorOnChange(form);
	}


	if (form.querySelector('.form-control__email-input')) {
		validateEmail(form);
		removeErrorOnFocus(form);
	}


	if (form.querySelector('.form-control__raffle-code')) {
		validateRaffleCode(form);
		removeErrorOnFocus(form);
	}

	const formIsInvalid = !form.querySelector('.form-group--error');

	return formIsInvalid;
};